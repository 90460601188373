import {useEffect, useRef, useState} from 'react'

const useFocus = () => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return {inputRef, focusInput}
}

export default useFocus
