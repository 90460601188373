import axios from 'axios'
const UPDATE_USER = '/users/update-user'
const GET_USER = '/users/get-user'
const CHANGE_PASSWORD = '/auth/change-password'
const UPDATE_USER_FIRST_LOGIN_STATUS = '/users/update-user-first-login'

// update user profile
export function updateUserProfile(userName: string) {
  return axios.put(UPDATE_USER, {
    name: userName,
  })
}

// change user password
export function changePassword(currentPassword: string, newPassword: string) {
  return axios.put(CHANGE_PASSWORD, {
    currentPassword: currentPassword,
    newPassword: newPassword,
  })
}
// get user by email
export function getUserByEmail(email: string) {
  return axios.get(GET_USER, {
    params: {
      email: email,
    },
  })
}

// change user password
export function updateUserFirstLoginStatus(id: number | undefined) {
  return axios.put(UPDATE_USER_FIRST_LOGIN_STATUS, {
    id
  })
}
