import {useEffect} from 'react'
import {useChatbotContext} from '../../context/ChatbotContext'
import {useLocation} from 'react-router-dom'
import _ from 'lodash'

interface IframeProps {
  id: string
}

const IBLOCIframe: React.FC<IframeProps> = ({id}) => {
  const location = useLocation()
  // is waystone style in URL
  const isWaystoneStyles =
    location.search.includes('Style=Waystone') ||
    location.search.includes('style=Waystone') ||
    location.search.includes('Style=waystone') ||
    location.search.includes('style=waystone')

  //   state hook
  const {SBLOCChartData, setSBLOCChartData, setIBLOCArray,IBLOCArray} = useChatbotContext()

  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent) => {
      // Ensure the message is from a trusted source
      if (event.origin === process.env.REACT_APP_VIVIDLI_URL) {
        if (event.data.type === 'TABLE_DATA') {
          const tableText = event.data.data.join('\n') // Ensure the rows are joined with a newline character
          copyToClipboard(tableText)
        } else if (event.data.type === 'IBLOCArray') {
          if (!_.isEqual(IBLOCArray, event.data.data)) {
            console.log('here')
            console.log(event.data.data)
            setIBLOCArray(event.data.data)
          }
        }
      }
    }

    window.addEventListener('message', handleReceiveMessage)

    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [])
  function copyToClipboard(text: any) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Data copied to clipboard successfully!')
      })
      .catch((err) => {
        // console.error('Failed to copy text: ', err)
      })
  }
  useEffect(() => {
      sendDataToIframe()
  }, [IBLOCArray])

  // Function to send data to the iFrame
  const sendDataToIframe = () => {
    const iframe = document.querySelector('iframe')
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        IBLOCArray,
        `${process.env.REACT_APP_VIVIDLI_URL}`
      )
    }
  }


  // Assuming you have access to the clipboard data in the parent page

  useEffect(() => {
    // Function to handle the clipboard data setting
    async function setIframeSrcWithClipboardData() {
      try {
        // Attempt to read text from the clipboard
        const text = encodeURIComponent(await navigator.clipboard.readText())

        // Get the iframe element by its ID and assert the correct type
        const iframe = document.getElementById('IBLOCIFRAME') as HTMLIFrameElement // Type assertion here

        // Check if the iframe exists to avoid null reference errors
        if (iframe) {
          // Construct the URL with the copied data as a query parameter
          iframe.src = `${process.env.REACT_APP_VIVIDLI_URL}/iblocPage?data=${text}`
          console.log(`Setting iframe src with data: ${text}`)
        } else {
          // Log or handle the error when iframe is not found
          console.error('Iframe not found')
        }
      } catch (error) {
        // Log the error if reading from the clipboard fails
        console.error('Failed to read clipboard:', error)
      }
    }

    // Call the function on component mount
    setIframeSrcWithClipboardData()

    // Set up the event listener for when the tab becomes visible again
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIframeSrcWithClipboardData()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
  //    setIframeSrcWithClipboardData()

  return (
    <>
      <iframe
        id={id}
        src={`${process.env.REACT_APP_VIVIDLI_URL}/iblocPage?${
          isWaystoneStyles ? 'Style=Waystone' : ''
        }&&waystone=true}`} // Replace with the URL of the web viewer
        title='Web Viewer'
        width='100%'
        style={{height: '100%', border: 'none'}}
        height='100%'
      ></iframe>
    </>
  )
}

export {IBLOCIframe}
