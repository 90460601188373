import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import useFocus from '../../hooks/useFocus'
import {IMessagesInfo, IResponseBotObject, ISidePanel} from '../core/_model'
import {toast} from 'react-toastify'
import {
  extractSubdomainFromCurrentUrl,
  getRandomNumber,
  htmlToText,
  sendAnalytics,
  sendToSheets,
  sendToWayStoneCompanySheets,
} from '../core/chatbotHelper'
import DOMPurify from 'dompurify'
import {ERROR} from '../../../../constants/AppConstants'
import {filterUserInput, submitChatRequest, submitLlamaRequest} from '../core/_request'
import {
  informationGatheringStrings,
  investmentTipsAndSuggestions,
  searchInProgressStrings,
  welcomeGreetingStrings,
} from '../core/TipsAndSuggestions'
import useScreenWidth from '../../hooks/useScreenWidth'
import {useChatbotContext} from '../../../context/ChatbotContext'
import {useAuth} from '../../../../modules/auth'
import {useParams} from 'react-router-dom'

export const useChatBotState = () => {
  // screen width custom hook
  const screenWidth = useScreenWidth()
  const {companyName} = useParams() // companyName must be defined in the route path
  const {currentUser} = useAuth()

  const {
    sidePanelPreferencesObj,
    setSidePanelPreferencesObj,
    setShowSlider,
    SBLOCChartData,
    setSBLOCChartData,
    IBLOCArray,
    setIBLOCArray,
  } = useChatbotContext()
  const isIBLOCInUrl = window.location.pathname.includes('/ibloc-chat-bot')
  // risk tolerance types
  const riskTypes = ['Low', 'Medium', 'High']
  // default preffered assets
  const defaultPrefferedAssets = ['Equities', 'Fixed Income', 'Cash', 'Stocks', 'Commodities']
  // define the validation schema for side-panel using Yup
  const ComparePerformanceSchema = Yup.object().shape({
    starting_amount: Yup.number(),
    contributions: Yup.number(),
    years_to_grow: Yup.number(),
    risk_tolerance: Yup.string(),
    preffered_assets: Yup.array(),
  })

  // define the initial form values for side-panel
  const initialValues: ISidePanel = {
    starting_amount: sidePanelPreferencesObj.starting_amount,
    contributions: sidePanelPreferencesObj.contributions,
    years_to_grow: sidePanelPreferencesObj.years_to_grow,
    risk_tolerance: sidePanelPreferencesObj.risk_tolerance,
    preffered_assets: sidePanelPreferencesObj.preffered_assets,
  }

  // formik handle submit
  const handleFormSubmit = async (values: ISidePanel) => {
    setSidePanelPreferencesObj(values)
  }

  // formik
  const formik = useFormik<ISidePanel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: ComparePerformanceSchema,
    onSubmit: handleFormSubmit,
  })

  // text area ref hook
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  // setting isFirstRender to true to avoid form submission on first render
  const isFirstRender = useRef(true)

  const [userResponse, setUserResponse] = useState<string>('')
  const [botResponse, setBotResponse] = useState<IResponseBotObject>({
    message: '',
    sender: 'bot',
  })
  const [previousBarData, setPreviousBarData] = useState<any>({})
  const [threadId, setThreadId] = useState<string | null>(null)
  const [messages, setMessages] = useState<IMessagesInfo[]>([])
  const [chartData, setChartData] = useState<any[]>([])
  const [prevChartData, setPrevChartData] = useState<any[]>([])
  const [showChat, setShowChat] = useState<boolean>(false)
  const {inputRef, focusInput} = useFocus()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)
  const [showSuggestedBubble, setShowSuggestedBubble] = useState<boolean>(true)
  const [suggestedMessages, setSuggestedMessages] = useState([
    {
      id: 1,
      message: 'At what interest rate does my SBLOC get full?',
    },
    {
      id: 2,
      message: 'What is the maximum interest rate so that my SBLOC is only 25% full in 15 years?',
    },
    {
      id: 3,
      message: `OK now what if I increased my amount borrowed to $100,000. But I also re-invested $50,000 of that at 15% returns? What does year 10 look like?`,
    },
    {
      id: 4,
      message:
        'I have two choices for re-investing. I can re-invest $200,000 into A and get 6% back. Or I can re-invest $100,000 into B and get 10% back. Compare them first, then tell me which one has a higher net return after 10 years and I should use for SBLOC re-investing for 10 years (also show me how is my SBLOC doing)?',
    },
  ])

  let domainName = extractSubdomainFromCurrentUrl()

  const notifyError = (msg: string) => toast.error(msg)
  useEffect(() => {
    sendAnalytics(isIBLOCInUrl ? 'IBLOC with Chat-Bot' : 'SBLOC with Chat-Bot')
  }, [])

  useEffect(() => {
    if (showChat) {
      focusInput()
    }
  }, [showChat, focusInput])

  // stacking up messages
  useEffect(() => {
    if (messages.length === 0) {
      submitChatRequestHandler()
    } else {
      let temp2 = [...messages]
      const botResponseFormatted: IMessagesInfo = {
        message: botResponse.message,
        sender: 'bot',
        chartData: chartData,
        prevChartData: prevChartData,
        messageType: botResponse.type,
      }
      temp2.push(botResponseFormatted)
      if (!botResponse.type) {
        temp2 = temp2.filter((message) => {
          return message.messageType === undefined || message.sender === 'user'
        })
      }
      // to keep track of whole conversation
      setMessages(temp2)
      setUserResponse('')
    }
  }, [botResponse])

  // adjust text area(search bar) height
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '30px' // reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px` // set the height based on content
    }
  }, [userResponse])

  // set formik side-panel form values
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const timeoutId = setTimeout(() => {
      formik.handleSubmit()
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [formik.values])

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')
  const parsedPersonalizationFormObj = personalizationFormObj
    ? JSON.parse(personalizationFormObj)
    : {}

  const sidePanelObj = ` Here are the user's preferences annualized_interest_rate=${
    SBLOCChartData?.annualized_interest_rate
  }, asset_rate_of_return: ${SBLOCChartData?.asset_rate_of_return}, borrowed_amount: ${
    SBLOCChartData?.borrowed_amount
  }, re_invest_rate_of_return: ${SBLOCChartData?.re_invest_rate_of_return}, re_invested_amount: ${
    SBLOCChartData?.re_invested_amount
  }, starting_amount: ${SBLOCChartData?.starting_amount}, years_to_grow: ${
    SBLOCChartData?.years_to_grow !== undefined && SBLOCChartData?.years_to_grow !== 0
      ? SBLOCChartData?.years_to_grow
      : 20
  }. Note: Please use these values while answering to the user, and if the values are null than use the personalized or default values. I repeat if the values are null than use the personalized or default values.`
  // const sidePanelObj = ''
  const Act_as_A_Junior_Advisor_For_SBLOC =
    'You are a Junior Advisor at a financial planning firm. Keep answers succinct, cordial, and business-like. But continue answering with analogies, especially gardening analogies when appropriate.'
  // default start message
  const startMessage = parsedPersonalizationFormObj?.risk_tolerance
    ? 'Say Hi to user at the start of each new thread and inform user that we have collected your personalization information and note please keep the answer very short. Also, ask user What questions can I help you with? or similar questions in friendly manner.'
    : 'Say Hi to user at the start of each new thread. Also, ask user What questions can I help you with? or similar questions in friendly manner'

  const submitChatRequestHandler = async () => {
    let timerId: any
    let counting = 0
    let message = ''
    try {
      setLoading(true)
      let messages: IMessagesInfo[] = []
      timerId = setInterval(() => {
        counting += 1
        switch (counting) {
          case 2:
            message += welcomeGreetingStrings[getRandomNumber(0, 19)]
            break
          case 6:
            message = welcomeGreetingStrings[getRandomNumber(0, 19)]
            break
        }

        if (message) {
          const newMessage: IMessagesInfo = {
            message: message,
            sender: 'bot',
            chartData: null,
            prevChartData: null,
            messageType: 'generatedFromLocal',
          }
          messages.push(newMessage)
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, newMessage]
            return updatedMessages
          })
          message = ''
        }

        if (counting >= 10) {
          clearInterval(timerId)
        }
      }, 1000)

      const res = await submitChatRequest(
        startMessage,
        threadId,
        personalizationFormObj,
        currentUser?.id,
        isIBLOCInUrl ? (IBLOCArray.length ? IBLOCArray : []) : []
      )

      const {text, existingThreadId} = res.data
      const newMessage = {
        message: text,
        sender: 'bot',
        chartData: null,
        prevChartData: null,
      }
      messages.push(newMessage)
      setMessages(messages)

      !threadId && setThreadId(existingThreadId)
    } catch (error: any) {
      if (error?.response?.status === 429) return notifyError(ERROR.too_many_requests)
      else if (error?.response?.status === 403)
        return notifyError('Oops! You have reached your daily tokens limit.')
      else notifyError(ERROR.message)
      // console.log(error?.response)
    } finally {
      clearInterval(timerId)
      setLoading(false)
      setShowChat(true)
    }
  }

  // event handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserResponse(e.target.value)
  }

  // handle form submit
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    isLlamaModel?: boolean,
    defaultMessage: string = ''
  ) => {
    e.preventDefault()
    setShowSuggestedBubble(false)
    setShowSlider(false)
    const userRes = defaultMessage ? defaultMessage : userResponse
    if (userRes.length < 1) return
    if (userRes === 'Thankyou') {
      setShowFeedbackModal(true)
      return setUserResponse('')
    }
    setShowSuggestedBubble(false)
    let tempArray = [...messages]
    const botResponseFormatted: IMessagesInfo = {
      message: userRes,
      sender: 'user',
      chartData: null,
      prevChartData: null,
    }
    tempArray.push(botResponseFormatted)
    setMessages(tempArray)
    setLoading(true)
    let timerId: any

    try {
      const responseFromFilter = await filterUserInput(userRes)
      console.log(responseFromFilter, 'responseFromFilter');
      console.log(userRes, 'userRes');
      if (!responseFromFilter.data) {
        setBotResponse({
          message: "I'm sorry, I can only answer questions related to finance and investment",
          sender: 'bot',
        })
        setChartData([])
        return
      }
      let counting = 0
      let message = ''

      timerId = setInterval(() => {
        counting += 1
        switch (counting) {
          case 10:
            message += informationGatheringStrings[getRandomNumber(0, 54)]
            break
          case 20:
            message += searchInProgressStrings[getRandomNumber(0, 99)]
            break
          case 30:
            message += investmentTipsAndSuggestions[getRandomNumber(0, 177)]
            break
          case 50:
            message += investmentTipsAndSuggestions[getRandomNumber(0, 177)]
            break
        }

        if (message) {
          setBotResponse({
            message: message,
            sender: 'bot',
            type: 'generatedFromLocal',
          })
        }
        message = ''
      }, 1000)
      // const startTime: any = new Date()
      let res
      if (isLlamaModel) {
        console.log('<<<<<--------------Start-------------------->>>>>')
        console.log('llama Model Confirmed.')
        console.log('<<<<<---------------End--------------------->>>>>')
        res = await submitLlamaRequest(userRes, IBLOCArray)
      } else {
        res = await submitChatRequest(
          userRes + sidePanelObj + Act_as_A_Junior_Advisor_For_SBLOC,
          threadId,
          personalizationFormObj,
          currentUser?.id,
          isIBLOCInUrl ? (IBLOCArray.length ? IBLOCArray : []) : []
        )
      }

      if (res.data.response.data) {
        setIBLOCArray(res.data.response.data)
        setBotResponse({
          message: res.data.response.updatedValue,
          sender: 'bot',
        })
      } else if (isLlamaModel && !res.data.response.data) {
        console.log('setting response here from llama model')
        setBotResponse({
          message: res.data.response,
          sender: 'bot',
        })
      } else {
        const {text, existingThreadId, chart_data, IBLOCCalculatedArray} = res.data
        setIBLOCArray(IBLOCCalculatedArray)
        if (
          chart_data.length === 1 &&
          chart_data[0].investment_name === previousBarData?.investment_name &&
          chart_data[0].investment_name !== undefined
        ) {
          let tempArray = [...chart_data] // Create a new array to avoid mutating the original
          tempArray.push(previousBarData)
          setChartData(tempArray)
        } else {
          let prev = chartData
          setPrevChartData(prev)
          setChartData(chart_data && chart_data)
          setSBLOCChartData(chart_data && chart_data)
          setSidePanelPreferencesObj(
            chart_data.length >= 1
              ? {
                  starting_amount: chart_data[0]?.starting_amount,
                  contributions: chart_data[0]?.original_contributions,
                  years_to_grow: chart_data[0]?.years_to_grow,
                  risk_tolerance: chart_data[0]?.risk_tolerance,
                  preffered_assets: sidePanelPreferencesObj?.preffered_assets,
                }
              : sidePanelPreferencesObj
          )
        }

        !existingThreadId && setThreadId(existingThreadId)
        // set response text
        setBotResponse({
          message: res.data?.response ? res.data.response : DOMPurify.sanitize(text),
          sender: 'bot',
        })

        if (chart_data.length === 1) {
          setPreviousBarData({...chart_data[0], oldValue: true})
        }
        const date = new Date()

        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

        const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date
          .getMinutes()
          .toString()
          .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`

        const receiverEmail = extractEmailKeyFromUrl(window.location.href)
          ? extractEmailKeyFromUrl(window.location.href)
          : currentUser?.email

        sendToSheets(
          userResponse,
          htmlToText(res.data?.response ? res.data.response : DOMPurify.sanitize(text)),
          receiverEmail ? receiverEmail : '',
          formattedDate + ' ' + formattedTime,
          domainName,
          isIBLOCInUrl ? true : false
        )
        sendToWayStoneCompanySheets(
          userResponse,
          htmlToText(res.data?.response ? res.data.response : DOMPurify.sanitize(text)),
          formattedDate + ' ' + formattedTime,
          companyName || ''
        )
      }
    } catch (error: any) {
      if (error?.response?.status === 429) return notifyError(ERROR.too_many_requests)
      else if (error?.response?.status === 403)
        return notifyError('Oops! You have reached your daily tokens limit.')
      else notifyError(ERROR.message)
    } finally {
      clearInterval(timerId)
      setLoading(false)
      setShowChat(true)
    }
  }

  // handle 'enter' key down
  const handleKeyDown = (event: any, isLlamaModel?: boolean) => {
    // check if Enter is pressed without the Shift key
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // prevent new line
      handleSubmit(event, isLlamaModel ? isLlamaModel : false)
    }
  }

  // extract email from URL
  const extractEmailKeyFromUrl = (url: string): string | null => {
    const urlParams = new URLSearchParams(url.split('?')[1])
    const emailValue = urlParams.get('email')
    return emailValue
  }

  // handle suggested messages
  const handleSuggestedMessages = (e: any, message: string) => {
    setUserResponse(message)
    handleSubmit(e, undefined, message)
  }

  return {
    formik,
    messages,
    setMessages,
    isLoading,
    setLoading,
    showSuggestedBubble,
    suggestedMessages: screenWidth > 991 ? suggestedMessages : suggestedMessages.slice(0, 2),
    userResponse,
    setUserResponse,
    inputRef,
    textAreaRef,
    riskTypes,
    prevChartData,
    defaultPrefferedAssets,
    handleSubmit,
    handleKeyDown,
    handleInputChange,
    handleSuggestedMessages,
    showFeedbackModal,
    setShowFeedbackModal,
  }
}
