import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {UserLayout} from '../../_metronic/layout/UserLayout'
import {HomePage} from '../user/pages/home/HomePage'
import PrivacyPolicy from '../user/modules/privacy-policy/PrivacyPolicy'
import {UnityBuild} from '../user/pages/home/UnityBuild'
import {ChatBotPage} from '../user/pages/home/ChatBotPage'
import {SBLOCPage} from '../user/pages/home/SBLOCPage'
import {IBLOCPage} from '../user/pages/home/IBLOCPage'
import {IBLOCChatBotPage} from '../user/pages/home/IBLOCChatBotPage'
import {useAuth} from '../modules/auth'
import ValidCompanyRoute from './ComponentValidator'
import { LlamaChatBotPage } from '../user/pages/home/LlamaChatBotPage'
const UserRoutes = () => {
  const AboutUs = lazy(() => import('../user/modules/about/AboutPage'))
  const ContactUs = lazy(() => import('../user/modules/contact/ContactPage'))
  const ProfilePage = lazy(() => import('../user/modules/profile/ProfilePage'))
  const TOCPage = lazy(() => import('../user/modules/TOC/TOCPage'))

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')
  const {currentUser, logout} = useAuth()

// let result
// try {
//   // Check if the input is already an array
//   if (typeof currentUser?.registered_companies === 'string') {
//     // Try to parse the string
//     result = JSON.parse(currentUser?.registered_companies)
//     console.log(result)
//   } else if (Array.isArray(currentUser?.registered_companies)) {
//     // If currentUser?.registered_companies is already an array, do nothing
//     result = currentUser?.registered_companies
//   } else {
//     throw new Error('Invalid input format')
//   }

//   console.log(result)
// } catch (error) {
//   console.error('Error parsing or processing input:', error)
// }

  return (
    <Routes>
      <Route element={<UserLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          index
          element={
            <Navigate
              to={!personalizationFormObj ? '/home' : `/${currentUser?.registered_companies[0]}`}
            />
          }
        />

        {/* Pages */}
        {/* <Route path='home' element={<PersonalizationForm />} /> */}

        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/:companyName/SBLOC-tool-only'
          element={
            <SuspensedView>
              <ValidCompanyRoute
                component={SBLOCPage}
                allowedCompanies={currentUser?.registered_companies || []}
              />
            </SuspensedView>
          }
        />
        <Route
          path='/:companyName'
          element={
            <SuspensedView>
              <ValidCompanyRoute
                component={ChatBotPage}
                allowedCompanies={currentUser?.registered_companies || []}
              />
            </SuspensedView>
          }
        />
        <Route
          path='ibloc-chat-bot'
          element={
            <SuspensedView>
              <IBLOCChatBotPage />
            </SuspensedView>
          }
        />
        <Route
          path='llama-chat-bot'
          element={
            <SuspensedView>
              <LlamaChatBotPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/asad'
          element={
            <SuspensedView>
              <IBLOCPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='/:companyName/SBLOC-tool-only'
          element={
            <SuspensedView>
              <SBLOCPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='SBLOC-tool-only'
          element={
            <SuspensedView>
              <SBLOCPage />
            </SuspensedView>
          }
        />

        <Route
          path='about'
          element={
            <SuspensedView>
              <AboutUs />
            </SuspensedView>
          }
        />

        <Route
          path='contact'
          element={
            <SuspensedView>
              <ContactUs />
            </SuspensedView>
          }
        />

        <Route
          path='policy'
          element={
            <SuspensedView>
              <PrivacyPolicy />
            </SuspensedView>
          }
        />

        <Route
          path='terms'
          element={
            <SuspensedView>
              <TOCPage />
            </SuspensedView>
          }
        />
        <Route
          path='unity-testing'
          element={
            <SuspensedView>
              <UnityBuild />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='home' element={<HomePage />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
        {/* <Route
          path='*'
          element={
            <Navigate
              to={!personalizationFormObj ? '/home' : `${currentUser?.registered_companies[0]}`}
            />
          }
        /> */}
      </Route>

      {/* <Route
        path='personal'
        element={
          <SuspensedView>
            <PersonalizationForm />
          </SuspensedView>
        }
      /> */}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {UserRoutes}
