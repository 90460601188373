import {useEffect, useState} from 'react'

const useScreenWidth = (): number => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = (): void => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenWidth
}

export default useScreenWidth
