import React from 'react'
import MetaTags from '../../../Meta/MetaTags'
import {PRIVACY_POLICY_PAGE_CONSTANTS} from '../../../constants/AppConstants'

const PrivacyPolicy = () => {
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />

      <div className='d-flex justify-content-center align-items-center'>
        <div className='card mt-5 w-100 w-md-75'>
          <div className='card-body'>
            <h1 className='text-dark fw-bolder mb-9 text-center'>
              {PRIVACY_POLICY_PAGE_CONSTANTS.heading}
            </h1>
            <p className='card-text mb-0'>{PRIVACY_POLICY_PAGE_CONSTANTS.paragraph}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
