import {escapeRegExp} from 'lodash'

interface Props {
  value: string
  setSearch: (value: string) => void
}

const DataTableSearch: React.FC<Props> = ({value, setSearch}) => {
  return (
    <input
      type='text'
      placeholder='Search'
      className='form-control w-25 mt-5'
      value={value}
      onChange={(e) => {
        setSearch(escapeRegExp(e.target.value))
        console.log(e.target.value)
      }}
    />
  )
}

export default DataTableSearch
