import Chats from './components/Chats'
import FeedBackModal from '../../../modals/FeedBackModal'
import {useChatBotState} from './components/ChatbotUtils'
interface chabotProps {
  isWayStoneStyled: boolean
  isLlamaModel?: boolean
}

const Chatbot: React.FC<chabotProps> = ({isWayStoneStyled, isLlamaModel = false}) => {
  const {
    messages,
    isLoading,
    showSuggestedBubble,
    suggestedMessages,
    userResponse,
    inputRef,
    textAreaRef,
    handleSubmit,
    handleKeyDown,
    handleInputChange,
    handleSuggestedMessages,
    showFeedbackModal,
    setShowFeedbackModal,
  } = useChatBotState()
  return (
    <div
      className={`chat-container d-flex flex-column justify-content-between mt-5 ${
        isWayStoneStyled ? 'waystone-background' : ''
      }`}
    >
      <Chats
        messages={
          isLlamaModel
            ? messages // Use the updated messages array
            : messages
        }
        isLoading={isLoading}
        isWaystoneStyled={isWayStoneStyled}
      />

      {/* feedback modal */}
      <FeedBackModal
        show={showFeedbackModal}
        onHide={() => setShowFeedbackModal(false)}
      ></FeedBackModal>

      <div className='position-sticky bottom-0' style={{backgroundColor: '#d0cfcd'}}>
        {/* suggestion bubble */}
        {showSuggestedBubble ? (
          <>
            <div
              className={`suggestion-bubble-container row m-0 mb-4 gap-2 d-lg-flex justify-content-around`}
            >
              {suggestedMessages.map((msg, index) => (
                <button
                  className={`suggestion-bubble-button col-md-6 p-0 ${
                    isWayStoneStyled ? 'montserrat-font' : ''
                  }`}
                  key={index}
                  disabled={isLoading}
                  onClick={(e) => handleSuggestedMessages(e, msg.message)}
                >
                  <p className={`suggestion-bubble-text text-start mb-0 px-5 py-1 `}>
                    {msg.message}
                  </p>
                </button>
              ))}
            </div>
          </>
        ) : (
          ''
        )}

        {/* chat search bar */}
        <form
          onSubmit={(e) => handleSubmit(e, isLlamaModel ? isLlamaModel : false)}
          className='chat-input-container d-flex px-5'
        >
          <textarea
            ref={(element: any) => {
              // set both refs to the same element
              textAreaRef.current = element
            }}
            disabled={isLoading}
            onChange={(e) => {
              handleInputChange(e)
            }}
            value={userResponse}
            placeholder='Message Chloee...'
            className='chat-input'
            onKeyDown={(e: any) => handleKeyDown(e, isLlamaModel ? isLlamaModel : false)}
          />
          <button disabled={isLoading} className='chat-button'>
            {isLoading ? (
              <span className='spinner-border spinner-border-sm' style={{color: '#fff'}}></span>
            ) : (
              <i className='fa fa-arrow-up' style={{color: '#fff'}} />
            )}
          </button>
        </form>
        <div
          className={`justify-content-center text-center ${
            isWayStoneStyled ? 'montserrat-font' : ''
          }`}
        >
          <p className='font-size-small'>
            Disclaimer: AI can make mistakes, and cannot give investment advice. Please confirm
            numbers, math, and any recommendations before giving to client.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Chatbot
