import {Button} from 'react-bootstrap'

interface IFeedbackInputFieldProps {
  onChange: (value: string) => void
  onSubmit: () => void
}

const FeedbackInputField: React.FC<IFeedbackInputFieldProps> = ({onChange, onSubmit}) => {
  return (
    <>
      <div className='input-container d-flex justify-content-center align-items-center mt-2'>
        <input
          id='comments'
          type='text'
          className='form-control ps-0 py-2 border-0'
          placeholder='thoughts?'
          onChange={(event) => onChange(event.target.value)}
        />
        <Button className='input-field-button me-1 mt-0' onClick={onSubmit}>
          ✓
        </Button>
      </div>
    </>
  )
}

export default FeedbackInputField
