import React from 'react'
import {Helmet} from 'react-helmet'
import {metaTags_data} from '../constants/AppConstants'

interface Props {
  title?: string | null
  siteName?: string | null
  description?: string | null
  logo?: string | null
  URL?: string | null
}

const MetaTags: React.FC<Props> = ({title, siteName, description, logo, URL}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description || metaTags_data.description} />
      <meta name='image' content={logo || metaTags_data.logo} />
      <meta name='url' content={URL || window.location.origin} />
      {/* SEO for social media sharing */}
      <meta property='og:title' content={title || metaTags_data.title} />
      <meta property='og:site_name' content={siteName || metaTags_data.siteName} />
      <meta property='og:url' content={URL || window.location.origin} />
      <meta property='og:description' content={description || metaTags_data.description} />
      <meta property='og:type' content='website' />
      <meta property='og:image' content={logo || metaTags_data.logo} />
      {/* SEO for twitter sharing */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={URL || window.location.origin} />
      <meta property='twitter:title' content={title || metaTags_data.title} />
      <meta property='twitter:description' content={description || metaTags_data.description} />
      <meta property='twitter:image' content={logo || metaTags_data.logo} />
    </Helmet>
  )
}

export default MetaTags
