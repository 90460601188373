// personalization dropdown options
export const optionsDict = {
  investment_goal: [
    {
      value: '',
      label: 'What is your primary objective for investing?',
      name: 'Investment Goal',
    },
    {
      value: 'Retirement',
      label: 'Retirement',
    },
    {
      value: 'Property Purchase',
      label: 'Property Purchase',
    },
    {
      value: 'Wealth Growth',
      label: 'Wealth Growth',
    },
    {
      value: 'Income Generation',
      label: 'Income Generation',
    },
  ],
  risk_tolerance: [
    {
      value: '',
      label: 'How would you rate your comfort level with the ups and downs of investments?',
      name: 'Risk Tolerance',
    },
    {
      value: 'Low',
      label: 'Low',
    },
    {
      value: 'Medium',
      label: 'Medium',
    },
    {
      value: 'High',
      label: 'High',
    },
  ],
  investment_experience: [
    {
      value: '',
      label: 'How would you describe your experience with investments?',
      name: 'Investment Experience',
    },
    {
      value: 'Beginner',
      label: 'Beginner',
    },
    {
      value: 'Intermediate',
      label: 'Intermediate',
    },
    {
      value: 'Experienced',
      label: 'Experienced',
    },
  ],
  time_horizon: [
    {
      value: '',
      label: 'Over what time frame are you planning to invest?',
      name: 'Time Horizon',
    },
    {
      value: '1 year < 1 year',
      label: '1 year < 1 year',
    },
    {
      value: '1-3 years > 1-3',
      label: '1-3 years > 1-3',
    },
    {
      value: '3-5 years > 3-5',
      label: '3-5 years > 3-5',
    },
    {
      value: '> 5 years',
      label: '> 5 years',
    },
  ],
  liquidity_needs: [
    {
      value: '',
      label: 'How important is quick access to your invested funds?',
      name: 'Liquidity Needs',
    },
    {
      value: 'Very Important',
      label: 'Very Important',
    },
    {
      value: 'Somewhat Important',
      label: 'Somewhat Important',
    },
    {
      value: 'Not Important',
      label: 'Not Important',
    },
  ],
  income_needs: [
    {
      value: '',
      label: 'Are you looking for investments that generate regular income?',
      name: 'Income Needs',
    },
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    },
  ],
  tech_comfort: [
    {
      value: '',
      label: 'How comfortable are you with technology and digital platforms?',
      name: 'Tech Comfort',
    },
    {
      value: 'Very Comfortable',
      label: 'Very Comfortable',
    },
    {
      value: 'Moderately Comfortable',
      label: 'Moderately Comfortable',
    },
    {
      value: 'Not Comfortable',
      label: 'Not Comfortable',
    },
  ],
}

// contributions frequency
export const contributionAndDividendsFrequency = [
  {
    label: 'How often can you commit to contributing towards your investments?',
    value: '',
  },
  {
    label: 'Weekly',
    value: 52,
  },
  {
    label: 'Bi-Monthly',
    value: 24,
  },
  {
    label: 'Monthly',
    value: 12,
  },
  {
    label: 'Quarterly',
    value: 4,
  },
  {
    label: 'Semi-Annually',
    value: 2,
  },
  {
    label: 'Yearly',
    value: 1,
  },
]
