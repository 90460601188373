import axios from 'axios'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'

const {v4: uuidv4} = require('uuid')

const generateVisitorId = () => {
  return uuidv4() // Generates a unique UUID
}

const CanvaPreview = () => {
  const {slug} = useParams() // Get the slug from the URL

  // Redirect to the backend referral handler on component mount
  useEffect(() => {
    const visitorId = localStorage.getItem('visitorId') || generateVisitorId()
    localStorage.setItem('visitorId', visitorId)

    // Construct the API endpoint URL
    const apiUrl = `waitlist/referral/${slug}?visitorId=${visitorId}`

    // Make an API call to the backend to log the referral
    axios
      .get(apiUrl)
      .then((response) => {
        console.log('Referral logged:', response.data)
      })
      .catch((error) => {
        console.error('Error logging referral:', error)
      })
    const timer = setTimeout(() => {
      window.location.href = `https://joinwaitlist.chloee.xyz/?slug=${slug}`
    }, 3000)

    // Return a function to clear the timeout if the component unmounts
    return () => clearTimeout(timer)
  }, [slug])

  return (
    <div className='join-waitlist-container'>
      <div className='spinner'></div>
    </div>
  )
}

export default CanvaPreview
