interface Props {
  message: string
}

const MessageComponent: React.FC<Props> = ({message}) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <p className='card-text text-center mb-0'>{message}</p>
      </div>
    </div>
  )
}

export default MessageComponent
