import {memo} from 'react'
import {usePersonalizationFormState} from './PersonalizationFormUtils'
import clsx from 'clsx'
import CustomInputField from '../../../../components/CustomInputField'
import {optionsDict, contributionAndDividendsFrequency} from '../core/_personalizationConfig'
import {PERSONALIZATION_FORM_CONSTANTS} from '../../../../constants/AppConstants'

const PersonalizationFormInputs: React.FC = () => {
  // destructuring compare performance utils state
  const {formik, handleCompoundingFreqChange} = usePersonalizationFormState()

  // handle select change
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = event.target
    formik.setFieldValue(name, value)
  }

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className='personalization-form-container mt-20 w-75'
        id='personalization-form'
      >
        <h4 className='mb-5 form-heading'>{PERSONALIZATION_FORM_CONSTANTS?.FORM_HEADING}</h4>
        {Object.entries(optionsDict).map(([selectName, options], _index) => {
          // extracting the 'name' property and rest of the properties separately
          const {name, ...fieldProps} = formik.getFieldProps(selectName)

          return (
            <div key={selectName} className='select-container mb-3 mb-md-5'>
              <label className='form-label text-dark fs-3 mb-0'>{options[0].name}</label>
              <select
                id={selectName}
                name={selectName}
                className='form-select dropdown'
                {...fieldProps} // spread the rest of the fieldProps here
                onChange={handleSelectChange}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )
        })}
        {/* initial investment amount */}
        <div className='fv-rowmb-3 mb-md-5'>
          <label className='form-label text-dark fs-3 mb-0'>Initial Investment Amount</label>
          <CustomInputField
            id='initial-investment-amount'
            className='form-control'
            placeholder='How much are you looking to invest initially?'
            decimalsLimit={2}
            maxLength={8}
            onValueChange={(value: any) => {
              const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
              formik.setFieldValue('initial_investment_amount', parsedValue)
            }}
            value={formik.values.initial_investment_amount}
          />
          {formik.touched.initial_investment_amount && formik.errors.initial_investment_amount && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.initial_investment_amount}</span>
              </div>
            </div>
          )}
        </div>
        {/* contributions */}
        <div className='fv-row mb-3 mb-md-5'>
          <label className='form-label text-dark fs-3 mb-0'>Contributions Capability</label>
          <CustomInputField
            id='contributions-capability'
            className='form-control'
            placeholder='How much can you comfortably contribute on a regular basis towards your investments?'
            title='How much can you comfortably contribute on a regular basis towards your investments?'
            decimalsLimit={2}
            maxLength={8}
            onValueChange={(value: any) => {
              const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
              formik.setFieldValue('contributions', parsedValue)
            }}
            value={formik.values.contributions}
          />
          {formik.touched.contributions && formik.errors.contributions && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.contributions}</span>
              </div>
            </div>
          )}
        </div>
        {/* contributions frequency */}
        <div className='select-container mb-3 mb-md-5'>
          <label className='form-label text-dark fs-3 mb-0'>Contributions Frequency</label>
          <select
            id='compounding-frequency'
            className='form-select dropdown'
            {...formik.getFieldProps('contributions_frequency')}
            onChange={handleCompoundingFreqChange}
          >
            {contributionAndDividendsFrequency.map((option) => {
              return (
                <option className='px-2' key={option.label} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </select>
        </div>
        {/* initial deposit capability */}
        <div className='fv-row mb-3 mb-md-5'>
          <label className='form-label text-dark fs-3 mb-0'>Initial Deposit Capability</label>
          <CustomInputField
            id='initial-deposit-capability'
            className='form-control'
            placeholder='For goals requiring a significant initial deposit (like property purchase), how much can you comfortably contribute upfront?'
            title='For goals requiring a significant initial deposit (like property purchase), how much can you comfortably contribute upfront?'
            decimalsLimit={2}
            maxLength={8}
            onValueChange={(value: any) => {
              const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
              formik.setFieldValue('initial_deposit_capability', parsedValue)
            }}
            value={formik.values.initial_deposit_capability}
          />
          {formik.touched.initial_deposit_capability && formik.errors.initial_deposit_capability && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.initial_deposit_capability}</span>
              </div>
            </div>
          )}
        </div>
        {/* asset preference */}
        <div className='fv-row mb-3 mb-md-5'>
          <label className='form-label text-dark fs-3 mb-0'>Asset Preference</label>
          <textarea
            placeholder='Are you looking to invest in any particular asset or investment vehicles?'
            autoComplete='off'
            {...formik.getFieldProps('asset_preference')}
            className={clsx(
              'form-control',
              {
                'is-invalid': formik.touched.asset_preference && formik.errors.asset_preference,
              },
              {
                'is-valid': formik.touched.asset_preference && !formik.errors.asset_preference,
              }
            )}
            style={{minHeight: '100px'}} // add this line to set the minimum height
          />
          {formik.touched.asset_preference && formik.errors.asset_preference && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.asset_preference}</span>
              </div>
            </div>
          )}
        </div>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='primary-btn w-100 border-0 mt-10 mb-20'
          style={{maxWidth: '22rem'}}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          Chloee, I’m excited! Do your thing!
        </button>
      </form>
    </>
  )
}

export default memo(PersonalizationFormInputs)
