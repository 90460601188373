import React from 'react'
import {Unity, useUnityContext} from 'react-unity-webgl'
import {CSSProperties} from 'react'

const UnityBuild = () => {
  const {unityProvider} = useUnityContext({
    loaderUrl: 'Gardens/Build/GardenBuild.loader.js',
    dataUrl: 'Gardens/Build/GardenBuild.data',
    frameworkUrl: 'Gardens/Build/GardenBuild.framework.js',
    codeUrl: 'Gardens/Build/GardenBuild.wasm',
  })

  const unityContainerStyle: CSSProperties = {
    width: '100vw', // 100% of the viewport width
    height: '80vh', // 100% of the viewport height
    position: 'absolute', // Position it absolutely to take the full screen
    top: 100,
    left: 0,
  }

  return (
    <>
      <Unity unityProvider={unityProvider} style={unityContainerStyle} />
    </>
  )
}

export {UnityBuild}
