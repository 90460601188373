import {Suspense, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {metaTags_data} from './constants/AppConstants'
import MetaTags from './Meta/MetaTags'
import {AuthInit} from './modules/auth'
import JoinWaitlistModal from './modals/JoinWaitlistModal'
import {useJoinWaitlistContext} from './user/context/JoinWaitlistContext'

const App = () => {
  const {showModal, setShowModal} = useJoinWaitlistContext()
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags
        title={metaTags_data.title}
        siteName={metaTags_data.siteName}
        description={metaTags_data.description}
        logo={metaTags_data.logo}
        URL={window.location.href}
      />

      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
        <JoinWaitlistModal show={showModal} onHide={() => setShowModal(false)} />
      </Suspense>
    </>
  )
}

export {App}
