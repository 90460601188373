import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {DataRow} from '../admin/modules/Users/core/_model'
import {updateUserTokens} from '../admin/modules/Users/core/_request'

interface SendTokensModalProps {
  user: DataRow | null
  show: boolean
  onHide: () => void
  updateTable: (
    id: any,
    dailyLimit: number | undefined,
    availableTokens: number | undefined
  ) => void
}

const validationSchema = Yup.object().shape({
  daily_tokens_limit: Yup.number().min(0, 'Please enter a positive number').required('Required'),
  available_tokens: Yup.number().min(0, 'Please enter a positive number').required('Required'),
})

const SendTokensModal: React.FC<SendTokensModalProps> = ({user, show, onHide, updateTable}) => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)
  const notifySuccess = (msg: string) => toast.success(msg)

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      daily_tokens_limit: user?.daily_tokens_limit,
      available_tokens: user?.available_tokens,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const res = await updateUserTokens(
          user?.id,
          values.daily_tokens_limit,
          values.available_tokens
        )
        notifySuccess(res?.data?.message)
        setIsLoading(false)
        updateTable(user?.id, values.daily_tokens_limit, values.available_tokens)
        onHide()
      } catch (error: any) {
        if (error?.response?.data) {
          notifyError(error?.response?.data?.message)
        } else {
          notifyError('Error occurred during the request.')
        }
        onHide()
        setIsLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update User Tokens</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className='form-group'>
            <label>Daily Tokens Limit</label>
            <input
              type='number'
              className='form-control'
              {...formik.getFieldProps('daily_tokens_limit')}
            />
            {formik.touched.daily_tokens_limit && formik.errors.daily_tokens_limit && (
              <div className='text-danger'>{formik.errors.daily_tokens_limit}</div>
            )}
          </div>
          <div className='form-group'>
            <label>Available Tokens</label>
            <input
              type='number'
              className='form-control'
              {...formik.getFieldProps('available_tokens')}
            />
            {formik.touched.available_tokens && formik.errors.available_tokens && (
              <div className='text-danger'>{formik.errors.available_tokens}</div>
            )}
          </div>
          <div className='form-group'>
            <button type='submit' className='btn btn-primary' disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default SendTokensModal
